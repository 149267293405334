import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Assay, Result } from '../../../../interfaces/assay.interface';
import { Lab } from '@lims-common-ux/lux';
import { IsNumericAssayPipe } from '../../../../pipes/is-numeric-assay.pipe';
import { IsDefinedTextPipe } from '../../../../pipes/is-defined-text.pipe';
import { IsSemiQuantitativePipe } from '../../../../pipes/is-semi-quantitative.pipe';

@Component({
  selector: 'app-details-result',
  templateUrl: './details-result.component.html',
  styleUrl: './details-result.component.scss',
  providers: [IsNumericAssayPipe, IsDefinedTextPipe, IsSemiQuantitativePipe],
})
export class DetailsResultComponent implements OnChanges {
  @Input() selectedAssay: Assay;
  @Input() result: Result;
  @Input() collapsable: boolean;
  @Input() muted: boolean;
  @Input() lab: Lab;

  @Output() newResult = new EventEmitter<Result>();

  @ViewChild('radioInput', { static: false }) resultSelectionRadio: ElementRef;

  presentationValue;
  expanded = false;

  constructor(
    private isNumericAssay: IsNumericAssayPipe,
    private isDefinedText: IsDefinedTextPipe,
    private isSemiQuantitative: IsSemiQuantitativePipe
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.selectedAssay !== undefined &&
      this.result !== undefined &&
      this.collapsable !== undefined &&
      this.muted !== undefined &&
      this.lab !== undefined
    ) {
      this.presentationValue = this.result.presentationValue;

      this.expanded =
        this.isNumericAssay.transform(this.selectedAssay) ||
        this.isDefinedText.transform(this.selectedAssay) ||
        this.isSemiQuantitative.transform(this.selectedAssay);

      this.collapsable = this.expanded ? false : this.muted;
    }
  }

  isArray(value: any[]): boolean {
    return Array.isArray(value);
  }

  getAssayResultEnteredByDisplayName(result: Result): string {
    return result.instrument ? result.instrument.name : result.enteredBy;
  }

  selectCurrentResult() {
    if (this.expanded && this.muted) {
      this.resultSelectionRadio.nativeElement.focus();
      this.newResult.emit(this.result);
    }
  }
}
