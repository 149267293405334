import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Link, Workspace, WorkspaceAccession } from '@lims-common-ux/lux';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Panel } from '../../panel/panel.interface';

export interface StandardWorkspaceAccession extends WorkspaceAccession {
  _links: {
    assays: Link;
    saveAssays: Link;
    panels: Link;
  };
}

interface PanelResponse {
  _embedded: {
    panels: Panel[];
  };
}

@Injectable()
export class WorkspaceAccessionService {
  constructor(private http: HttpClient) {}

  loadAccession(accessionLink: Link, accessionId: string): Observable<Accession> {
    const accessionUrl = accessionLink.href.replace('{accessionId}', accessionId);
    return this.http.get<Accession>(accessionUrl);
  }

  /**
   * Assume we are loading a Standard Workspace here as we shouldn't be getting details on any other workspace in this
   * context.
   */
  loadWorkspaceAccession(accession: Accession, currentWorkspace: Workspace): Observable<StandardWorkspaceAccession> {
    const accessionWorkspace = accession._embedded.workspaces.filter((ws) => ws.id === currentWorkspace.id)[0];
    return this.http.get<StandardWorkspaceAccession>(accessionWorkspace._links.accession.href);
  }

  loadPanels(accession: StandardWorkspaceAccession): Observable<Panel[]> {
    return this.http.get<PanelResponse>(accession._links.panels.href).pipe(
      map((embeddedPanels: PanelResponse) => {
        if (embeddedPanels?._embedded?.panels?.length) {
          return embeddedPanels._embedded.panels;
        } else {
          return [];
        }
      })
    );
  }
}
