import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuditService, Link, Workspace } from '@lims-common-ux/lux';
import { AppStateService } from '../../app-state.service';
import { StandardWorkspaceAccession, WorkspaceAccessionService } from '../accession/workspace-accession.service';
import { AssayService } from '../assay/assay.service';
import { LoadedAccessionInfo } from '../accession/loaded-accession.class';
import { Router } from '@angular/router';

export interface QueueLinks {
  _links: {
    next: Link;
    self: Link;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WorkspaceQueueService {
  constructor(
    public appStateService: AppStateService,
    private assayService: AssayService,
    private workspaceService: WorkspaceAccessionService,
    private auditService: AuditService,
    private http: HttpClient,
    private router: Router
  ) {}

  advanceQueue(workspaceQueueUrl: Link, currentWorkspace: Workspace): Observable<LoadedAccessionInfo> {
    const loadedAccession = new LoadedAccessionInfo();

    return this.http.get<QueueLinks>(workspaceQueueUrl.href, { observe: 'response' }).pipe(
      catchError((err) => {
        this.navigateToEmptyQueue(this.appStateService.lab.id, currentWorkspace.id);
        return of(err);
      }),
      map((response) => {
        if (response.status === 200) {
          const workspaceQueue = response.body;
          this.appStateService.workspaceQueueNextUrl = workspaceQueue._links.next;
          return workspaceQueue;
        } else {
          this.navigateToEmptyQueue(this.appStateService.lab.id, currentWorkspace.id);
          return null;
        }
      }),
      switchMap((queueResponse) => {
        if (!queueResponse) {
          return of(null);
        } else {
          return this.workspaceService.loadAccession(
            this.appStateService.currentWorkspace._links.accession,
            queueResponse?.accessionId
          );
        }
      }),
      switchMap((accessionHeader) => {
        if (accessionHeader) {
          return this.workspaceService
            .loadWorkspaceAccession(accessionHeader, this.appStateService.currentWorkspace)
            .pipe(
              map((accession: StandardWorkspaceAccession) => {
                this.appStateService.accessionHeader = accessionHeader;
                this.appStateService.accession = accession;
                return accession;
              })
            );
        } else {
          return of(null);
        }
      }),
      // Need workspace accession to be loaded before we get panels and assays. Panels and Assays can also
      // be loaded concurrently
      switchMap(() => {
        if (this.appStateService.accession) {
          return combineLatest([
            this.assayService.loadWorkspaceAssays(this.appStateService.accession),
            this.workspaceService.loadPanels(this.appStateService.accession),
          ]);
        } else {
          return of(null);
        }
      }),
      switchMap((assaysAndPanels) => {
        this.appStateService.queueWorkspace = true;
        if (assaysAndPanels) {
          loadedAccession.accession = this.appStateService.accession;
          loadedAccession.resultAccession = this.appStateService.accessionHeader;
          loadedAccession.assays = assaysAndPanels[0];
          loadedAccession.panels = assaysAndPanels[1];
          this.auditService.fireAccessionLoaded(loadedAccession.accession.accessionId);
        } else {
          loadedAccession.accession = null;
          loadedAccession.resultAccession = null;
          loadedAccession.assays = [];
          loadedAccession.panels = [];
          this.appStateService.workspaceQueueNextUrl = null;
        }
        return of(loadedAccession);
      })
    );
  }

  navigateToEmptyQueue(labId, workspaceId) {
    this.router.navigate(['lims-results', labId, 'workspaces', workspaceId, 'empty-queue']);
  }
}
