import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { Observable, of } from 'rxjs';
import { Workspace } from '@lims-common-ux/lux';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class WorkspaceResolver {
  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private titleService: Title
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Workspace> {
    const workspaceId = route.paramMap.get('workspace');
    this.appStateService.currentWorkspace = this.appStateService.workspaces.find(
      (ws) => ws.id === workspaceId && ws.workspaceType === 'STANDARD_WORKSPACE'
    );
    this.titleService.setTitle(this.appStateService.currentWorkspace?.name);
    // This has the potential of returning a null workspace if we are trying to view an accession from another lab
    // If this is the case, the workspace will be determined in the accession resolver.
    return of(this.appStateService.currentWorkspace);
  }
}
