import { Pipe, PipeTransform } from '@angular/core';
import { Assay } from '../interfaces/assay.interface';

@Pipe({
  name: 'hasAssay',
  standalone: true,
})
export class HasAssayPipe implements PipeTransform {
  transform(assays: Assay[], testCode: string): Assay | undefined {
    return assays?.find((assay) => assay.testCode === testCode);
  }
}
