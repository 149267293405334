<div
  #wrapper
  class="multi-result-value-wrapper"
  (focusout)="handleFocusOut($event)"
  [class.spot-form--error]="displayError !== null && !inputHasFocus()">
  <input
    #resultInput
    autocomplete="off"
    [tabindex]="tabindex"
    class="spot-form__input"
    data-cy="result-input semi-quantitative-combo-input"
    spellcheck="false"
    [attr.aria-disabled]="disabled"
    [readonly]="disabled"
    [name]="name"
    [placeholder]="placeholder"
    (input)="handleInput($event)"
    (keydown.alt.e)="toggleEditMode($event)"
    (keydown.space)="handleSpace($event)"
    (keydown.enter)="handleEnter($event)"
    (keydown.arrowup)="handleArrowUp($event)"
    (keydown.arrowdown)="handleArrowDown($event)"
    (keydown.escape)="close($event)"
    (keydown.alt.space)="handleBulkSelect($event)"
    (focus)="handleFocus($event)"
    (focusout)="verifyCompleteEntry()"
    type="text" />
  <span class="spot-form__select-open-indicator">
    <svg
      class="spot-icon search-icon"
      aria-labelledby="title"
      [class.has-error]="displayError !== null"
      [class.has-focus]="initialFocus">
      <use xlink:href="../../assets/icons/spot_icons.svg#search"></use>
    </svg>
  </span>

  <ng-container [ngSwitch]="displayError">
    <div
      *ngSwitchCase="errorTypes.NO_MATCHING_OBSERVATIONS"
      class="spot-form__field-error"
      role="alert"
      data-cy="semi-quantitative-result-input-error">
      <span class="spot-form__field-error-text" data-cy="result-value-error">
        {{ 'ERRORS_AND_FEEDBACK.NO_MATCHES' | translate }}
      </span>
    </div>
    <div
      *ngSwitchCase="errorTypes.VALUE_REQUIRED"
      class="spot-form__field-error"
      role="alert"
      data-cy="semi-quantitative-result-save-error">
      <span class="spot-form__field-error-text" data-cy="result-value-error">
        {{ 'ERRORS_AND_FEEDBACK.MUST_ENTER_RESULT_VALUE' | translate }}
      </span>
    </div>
    <div
      *ngSwitchCase="errorTypes.QUANTIFICATION_NOT_ALLOWED"
      class="spot-form__field-error"
      role="alert"
      data-cy="quantification-not-allowed-error">
      <span class="spot-form__field-error-text" data-cy="result-value-error">
        {{ 'ERRORS_AND_FEEDBACK.QUANTIFICATION_NOT_ALLOWED' | translate }}
      </span>
    </div>
    <div
      *ngSwitchCase="errorTypes.QUANTIFICATION_REQUIRED"
      class="spot-form__field-error"
      role="alert"
      data-cy="quantification-required-error">
      <span class="spot-form__field-error-text" data-cy="result-value-error">
        {{ 'ERRORS_AND_FEEDBACK.QUANTIFICATION_REQUIRED' | translate }}
      </span>
    </div>
    <div
      *ngSwitchCase="errorTypes.OUT_OF_RANGE"
      class="spot-form__field-error"
      role="alert"
      data-cy="quantification-required-error">
      <span class="spot-form__field-error-text" data-cy="result-value-error">
        {{ 'ERRORS_AND_FEEDBACK.OUT_OF_RANGE' | translate }}
      </span>
    </div>
  </ng-container>

  <div [hidden]="hidden" class="search-results-wrapper" data-cy="defined-text-results">
    <ng-container *ngFor="let item of filteredResultOptions | slice: 0 : 4; let index = index; let last = last">
      <button
        #resultsListItem
        tabindex="-1"
        luxLayout="row"
        luxLayoutAlign="start start"
        class="result-list-item"
        data-cy="semi-quantitative-combo-item"
        type="button"
        [class.default-selection]="index === 0"
        (click)="selectOption(item, $event)"
        [name]="item.code">
        <span
          luxFlex="50px"
          *ngIf="getShortCodeByValue(item.code) && showPrefix"
          (click)="selectOption(item, $event)"
          class="prefix"
          >{{ getShortCodeByValue(item.code) }}</span
        >
        <span luxFlex class="content">{{ item.display }}</span>
      </button>
    </ng-container>
  </div>

  <div *ngIf="value?.length > 0 && !disabled" class="spot-form__microcopy">
    <div>
      {{ 'HOT_KEY_INSTRUCTIONS.EDIT' | translate }}
    </div>
  </div>
</div>

<div
  *ngIf="value?.length > 0"
  (dblclick)="toggleEditMode($event)"
  class="selections"
  data-cy="semi-quantitative-combo-selections"
  luxLayout="column">
  <ng-container *ngFor="let item of value; let index = index; let first = first">
    <div
      class="selection"
      *ngIf="item"
      data-cy="multi-result-selection"
      luxFlex
      luxLayout="row"
      luxLayoutAlign="space-between start">
      <div luxLayout="column">
        <div data-cy="result-value">
          {{ getObservationDisplayTextByValue(item.typeCode) }}
        </div>

        <div>
          <span *ngIf="getRangeDisplayByCount(item)" class="result-count" data-cy="result-count">{{
            getRangeDisplayByCount(item)
          }}</span>
        </div>
      </div>
      <div luxLayout="column" luxLayoutAlign="start end" luxFlex="18px">
        <span
          #deleteIcons
          (click)="removeResultValue(index)"
          (keydown.enter)="removeResultValue(index)"
          (keydown.alt.e)="toggleEditMode($event)"
          *ngIf="editMode"
          tabindex="1"
          class="result-delete-icon">
          <svg data-cy="result-delete-icon" class="spot-icon" aria-labelledby="title">
            <use xlink:href="../../assets/icons/spot_icons.svg#delete"></use>
          </svg>
        </span>
      </div>
    </div>
  </ng-container>
</div>
