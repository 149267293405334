import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WorkspaceComponent } from './workspace.component';
import { AccessionComponent } from './accession/accession.component';
import { RouterModule } from '@angular/router';
import { WorkspaceAccessionService } from './accession/workspace-accession.service';
import { AssayCardComponent } from './assay/assay-card/assay-card.component';
import {
  ApplicationPipesModule,
  LabNotesComponent,
  LUX,
  LuxLayoutModule,
  SamplesModule,
  SnackbarModule,
  WorkspacesModule,
} from '@lims-common-ux/lux';
import { AssayService } from './assay/assay.service';
import { TranslateModule } from '@ngx-translate/core';

// tslint:disable-next-line:max-line-length
import { SemiQuantitativeResultInputComponent } from './assay/assay-card/semi-quantitative-result-input/semi-quantitative-result-input.component';
import { SemiQuantitativeValueDirective } from './assay/assay-card/semi-quantitative-result-input/semi-quantitative-value.directive';
import { NumericResultInputComponent } from './assay/assay-card/numeric-result-input/numeric-result-input.component';
import { NumericValueValidatorDirective } from './assay/assay-card/numeric-result-input/numeric-value-validator.directive';
import { AssayDetailsComponent } from './assay/assay-details/assay-details.component';
// tslint:disable-next-line:max-line-length
import { SemiQuantitativeResultComboComponent } from './assay/assay-card/semi-quantitative-result-combo/semi-quantitative-result-combo.component';
import { DefinedTextNumericComboComponent } from './assay/assay-card/defined-text-numeric-combo/defined-text-numeric-combo.component';
import { DefinedMultiTextComponent } from './assay/assay-card/defined-multi-text/defined-multi-text.component';
import { WorkspaceNavigationComponent } from './workspace-navigation/workspace-navigation.component';
import { PanelModule } from '../panel/panel.module';
import { DetailsKeyComponent } from './assay/assay-details/details-key/details-key.component';
import { FreeTextComponent } from './assay/assay-card/free-text/free-text.component';
import { EmptyQueueComponent } from './workspace-queue/empty-queue/empty-queue.component';
import { IsNumericAssayPipe } from '../pipes/is-numeric-assay.pipe';
import { DetailsResultComponent } from './assay/assay-details/details-result/details-result.component';
import { IsDefinedTextPipe } from '../pipes/is-defined-text.pipe';
import { IsSemiQuantitativePipe } from '../pipes/is-semi-quantitative.pipe';
import { HasAssayPipe } from '../pipes/has-assay.pipe';

@NgModule({
  declarations: [
    WorkspaceComponent,
    AccessionComponent,
    AssayCardComponent,
    SemiQuantitativeResultInputComponent,
    SemiQuantitativeValueDirective,
    NumericResultInputComponent,
    NumericValueValidatorDirective,
    AssayDetailsComponent,
    SemiQuantitativeResultComboComponent,
    DefinedTextNumericComboComponent,
    DefinedMultiTextComponent,
    WorkspaceNavigationComponent,
    DetailsKeyComponent,
    FreeTextComponent,
    EmptyQueueComponent,
    DetailsResultComponent,
  ],
  imports: [
    TranslateModule.forChild({}),
    FormsModule,
    CommonModule,
    RouterModule,
    LUX,
    LuxLayoutModule,
    ApplicationPipesModule,
    SnackbarModule,
    PanelModule,
    SamplesModule,
    WorkspacesModule,
    IsNumericAssayPipe,
    LabNotesComponent,
    IsDefinedTextPipe,
    IsSemiQuantitativePipe,
    HasAssayPipe,
  ],
  providers: [WorkspaceAccessionService, AssayService],
  exports: [WorkspaceNavigationComponent],
})
export class WorkspaceModule {}
