import { Directive, HostListener } from '@angular/core';

@Directive()
export abstract class ComponentCanDeactivateDirective {
  abstract canDeactivate(): boolean;

  // We can not apply custom text or styling to browser/window level events
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}
