import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable, of, withLatestFrom } from 'rxjs';
import { Lab, LabsService, Link, Sample, User, WorkQueue, Workspace } from '@lims-common-ux/lux';
import { Accession as AccessionHeader } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { AssayCardComponent } from './workspace/assay/assay-card/assay-card.component';
import { Panel } from './panel/panel.interface';
import { ApplicationInitService } from './application-init.service';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { StandardWorkspaceAccession } from './workspace/accession/workspace-accession.service';
import { DEFAULT_TITLE } from './app.defaults';
import { ResultsDataResource } from '@lims-common-ux/lux/lib/data-resources/results-data-resource.interface';
import { Assay } from './interfaces/assay.interface';

export interface AppState {
  workspaces: Workspace[];
  labs: Lab[];
  lab: Lab;
  accession: StandardWorkspaceAccession;
  currentWorkspace: Workspace;
  accessionHeader?: AccessionHeader;
  panels?: Observable<Panel[]>;
  hasSavableChanges: Observable<boolean>;
  currentAssay?: AssayCardComponent;
  user: User;
  commentsDataSource: Link;
  bulkSelectedAssays: Assay[];
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService implements AppState {
  private _lab: Lab = null;
  private _accession: StandardWorkspaceAccession;
  private _workspaces: Workspace[];
  private _accessionHeader: AccessionHeader;
  private _currentAssay: AssayCardComponent = null;
  public currentAssaySub = new BehaviorSubject<AssayCardComponent>(this._currentAssay);
  private isInitialized = false;
  private _loading = true;
  public focusFirstAssayEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public accessionChangedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  private resultsLinks: ResultsDataResource;

  queueWorkspace = false;
  workspaceQueueNextUrl: Link;
  workspaceQueueEmptyMessageVisible: boolean;
  workQueues: WorkQueue[];
  currentWorkspace: Workspace;
  private _currentSample = new BehaviorSubject<Sample>(null);
  private _currentSample$ = this._currentSample.asObservable();
  get currentSample$(): Observable<Sample> {
    return this._currentSample$;
  }

  // Needed for can deactivate guard
  private hasSavableChangesSub = new BehaviorSubject<Observable<boolean>>(of(false));

  constructor(
    @Inject('Document') private document: any,
    private initService: ApplicationInitService,
    private labsService: LabsService
  ) {}

  configureSharedLinks(resource: ResultsDataResource) {
    this.resultsLinks = resource;
  }

  triggerFocusFirstAssay() {
    this.focusFirstAssayEvent.emit(true);
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(isLoading: boolean) {
    if (!this.isInitialized && !this._loading) {
      this.isInitialized = true;
    }

    if (isLoading) {
      this.document.getElementById('app-loader').style.display = 'block';

      if (this.isInitialized) {
        this.document.getElementById('app-loader').classList.add('waiting-on-interaction');
      }
    } else {
      this.document.getElementById('app-loader').style.display = 'none';
    }

    this._loading = isLoading;
  }

  set hasSavableChanges(obs: Observable<boolean>) {
    this.hasSavableChangesSub.next(obs.pipe(shareReplay(1)));
  }

  get hasSavableChanges(): Observable<boolean> {
    return this.hasSavableChangesSub.asObservable().pipe(switchMap((obs) => obs));
  }

  get workspaces(): Workspace[] {
    return this._workspaces;
  }

  set workspaces(wsArr: Workspace[]) {
    this._workspaces = wsArr;
  }

  get labs(): Lab[] {
    return this.initService.staticAppData.labs;
  }

  set lab(lab: Lab) {
    this._lab = lab;
    this.labsService.currentLab = lab;
    this.currentWorkspace = null;
  }

  get lab(): Lab {
    return this._lab;
  }

  get env(): string {
    return this.initService.staticAppData.environment;
  }

  get defaultPageTitle(): string {
    let title = `${DEFAULT_TITLE}`;

    if (this.currentWorkspace?.name) {
      title = this.currentWorkspace.name;
    }

    if (this.initService.staticAppData.environment === 'uat' || this.initService.staticAppData.environment === 'exp') {
      title += ` (${this.initService.staticAppData.environment})`;
    }

    return title;
  }

  get accessionHeader(): AccessionHeader {
    return this._accessionHeader;
  }

  set accessionHeader(accessionHeader: AccessionHeader) {
    this._accessionHeader = accessionHeader;
  }

  get accession(): StandardWorkspaceAccession {
    return this._accession;
  }

  set accession(accession: StandardWorkspaceAccession) {
    this._accession = accession;
  }

  get user(): User {
    return this.initService.staticAppData.currentUser;
  }

  get currentAssay(): AssayCardComponent {
    return this._currentAssay;
  }

  set currentAssay(assayCardCmp: AssayCardComponent) {
    this._currentAssay = assayCardCmp;
    this.currentAssaySub.next(this._currentAssay);
    if (this.accession?.samples && this._currentAssay) {
      this.currentSample = this.accession.samples.find((sample) =>
        sample.testAssociations.includes(this._currentAssay.assay.testCode)
      );
    }
  }

  private _bulkSelectedAssays$ = new BehaviorSubject<{ [key: string]: Assay }>({});
  bulkSelectedAssays$ = this._bulkSelectedAssays$
    .asObservable()
    .pipe(map((assays) => Object.values(assays).filter((assay) => assay)));

  get bulkSelectedAssays(): Assay[] {
    return Object.values(this._bulkSelectedAssays$.value).filter((assay) => assay);
  }
  bulkSelectAssay(assay: Assay) {
    const currentBulkSelectedAssays = this._bulkSelectedAssays$.value;
    currentBulkSelectedAssays[assay.testCode] = assay;
    this._bulkSelectedAssays$.next(currentBulkSelectedAssays);
  }
  bulkUnselectAssay(assay: Assay) {
    const currentBulkSelectedAssays = this._bulkSelectedAssays$.value;
    currentBulkSelectedAssays[assay.testCode] = null;
    this._bulkSelectedAssays$.next(currentBulkSelectedAssays);
  }
  clearBulkSelectedAssays() {
    this._bulkSelectedAssays$.next({});
  }
  refreshBulkSelectedAssays() {
    const currentBulkSelectedAssays = this._bulkSelectedAssays$.value;
    this._bulkSelectedAssays$.next(currentBulkSelectedAssays);
  }

  currentAssayWithinBulkSelected$ = this.currentAssaySub.pipe(
    debounceTime(300), // current assay origin comes from focus event making it bouncy, causing some visual artefacts in details tab
    withLatestFrom(this._bulkSelectedAssays$),
    map(([currentAssay, bulkSelectedAssays]) =>
      currentAssay ? !!bulkSelectedAssays[currentAssay.assay.testCode] : false
    )
  );

  bulkSelectedAssaysRepeatDisabled$ = this.bulkSelectedAssays$.pipe(
    map((bulkSelectedAssays) =>
      bulkSelectedAssays.some((assay) => !assay.canRepeat || assay.repeatRequested || !assay.canModify)
    )
  );

  bulkSelectedAssaysNoResultDisabled$ = this.bulkSelectedAssays$.pipe(
    map((bulkSelectedAssays) => bulkSelectedAssays.some((assay) => assay?.updatedResult?.noResult || !assay.canModify))
  );

  get bulkSelectedAssaysNoResultDisabled() {
    return this.bulkSelectedAssays.some((assay) => assay?.updatedResult?.noResult || !assay.canModify);
  }

  get bulkSelectedAssaysRepeatDisabled() {
    return this.bulkSelectedAssays.some((assay) => !assay.canRepeat || assay.repeatRequested || !assay.canModify);
  }

  bulkSelectedAssaysCanModify$ = this.bulkSelectedAssays$.pipe(
    map((bulkSelectedAssays) => bulkSelectedAssays.every((assay) => assay.canModify))
  );

  get currentAssayWithinBulkSelected(): boolean {
    return this.bulkSelectedAssays.some((assay) => assay.testCode === this.currentAssay.assay.testCode);
  }

  get commentsDataSource(): Link {
    return this.verifyAndGetResultLinks('comments');
  }

  get accessionLink(): Link {
    return this.verifyAndGetResultLinks('accession');
  }

  get addLabNoteLink(): Link {
    return this.verifyAndGetResultLinks('addLabNote');
  }

  get getLabNotesLink(): Link {
    return this.verifyAndGetResultLinks('getLabNotes');
  }

  get accessionSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionSearch');
  }

  get advancedAccessionSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionAdvancedSearch');
  }

  set currentSample(sample: Sample) {
    this._currentSample.next(sample);
  }

  private verifyAndGetResultLinks(linkName: string): Link {
    const link = this.resultsLinks?._links[linkName];

    if (link == null) {
      throw new Error(
        'Result links not set when looking for link ' +
          linkName +
          '. Please use `configureSharedLinks` to set this data appropriately.'
      );
    } else {
      return link;
    }
  }
}
