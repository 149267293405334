<div *ngIf="currentWorkspace" id="workspace-top">
  <lux-workspace-links [accession]="headerAccession" [currentWorkspace]="currentWorkspace"> </lux-workspace-links>
  <div luxLayout="row" luxLayoutAlign="space-between center" luxLayoutGap="13px">
    <!-- WORKSPACE NAME -->
    <div class="workspace-name" id="selected-workspace" data-cy="selected-workspace">{{ currentWorkspace.name }}</div>
    <!-- /WORKSPACE NAME -->
  </div>
  @if (chemAsStandardWorkspaceFeatureIsOn) {
    <lux-samples
      [samples]="workspaceAccession?.samples"
      [selectedSample]="selectedSample$ | async"
      (sampleSelectionChanged)="handleSampleChange($event)"></lux-samples>
  } @else {
    <lux-samples [samples]="workspaceAccession?.samples"></lux-samples>
  }
</div>
