import { Injectable } from '@angular/core';
import { Assay } from '../../../interfaces/assay.interface';

export class AssaysAndVersions {
  name: string;
  version: number;
}

@Injectable({
  providedIn: 'root',
})
export class AssayCardService {
  initialAssaysAndVersions: AssaysAndVersions[];
  currentAssaysAndVersions: AssaysAndVersions[];

  getAssaysAndVersion(assays: Assay[]) {
    return assays.map((assay) => {
      return {
        name: assay.name,
        version: assay.operationalId?.version,
      };
    });
  }

  setInitialAssaysAndVersions(assays: Assay[]): void {
    this.initialAssaysAndVersions = this.getAssaysAndVersion(assays);
  }

  setCurrentAssaysAndVersions(assays: Assay[]): void {
    this.currentAssaysAndVersions = this.getAssaysAndVersion(assays);
  }

  getSavedAssays() {
    const allSavedAssays: AssaysAndVersions[] = [];

    this.currentAssaysAndVersions?.forEach((versionedAssay) => {
      const savedAssay = this.initialAssaysAndVersions?.find((initialVersionedAssay) => {
        if (
          initialVersionedAssay.name === versionedAssay.name &&
          initialVersionedAssay.version !== versionedAssay.version
        ) {
          return versionedAssay;
        }
      });

      if (savedAssay) {
        return allSavedAssays.push(savedAssay);
      }
    });

    return allSavedAssays.length > 0 ? allSavedAssays : null;
  }

  reset() {
    this.initialAssaysAndVersions = [];
    this.currentAssaysAndVersions = [];
  }
}
