import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Comment, DefinedTextValue, Link } from '@lims-common-ux/lux';
import { Assay, ResultInterval } from '../../interfaces/assay.interface';
import { StandardWorkspaceAccession } from '../accession/workspace-accession.service';
import { Panel } from '../../panel/panel.interface';

interface AssayResponse {
  _embedded: {
    assays: Assay[];
  };
}

interface AssayUpdateResponse {
  updated: [{ _links: { self: Link } }];
}

interface AssayUpdate {
  operationalId: string;
  value?: any;
  actionType: string;
}

interface AssayUpdateToPreviousResult {
  operationalId: string;
  value: string;
  actionType: string;
}

export class ConflictError {
  constructor(public source: any) {}
}

/**
 * Sets up the structure for updating result values and defining the expected structure when ever assays are loaded.
 */
const setUpdateResult = tap((assays: Assay[]) => {
  assays.forEach((assay) => {
    // defaults presentation value on all assays to mirror status
    assay.presentationStatus = assay.status;
    if (assay.result) {
      // translates noResult values into separate properties so we can use them in the UI
      assay.updatedResult = {
        value: assay.result.value.noResult ? '' : copyArrayIfRequired(assay.result.value),
        noResult: assay.result.value.noResult,
      };
    } else {
      assay.result = { resultId: null, value: null, enteredBy: null, timestamp: null, presentationValue: null };
      assay.updatedResult = { value: null, noResult: false };
    }
  });
});

// Need to make a new array so that if we modify the result, it doesn't affect the original
function copyArrayIfRequired(assayResult: any): any {
  if (Array.isArray(assayResult)) {
    return [...assayResult];
  } else {
    return assayResult;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AssayService {
  constructor(private http: HttpClient) {}

  loadWorkspaceAssays(wsa: StandardWorkspaceAccession): Observable<Assay[]> {
    return this.http.get<AssayResponse>(wsa._links.assays.href).pipe(
      map((embeddedAssays) => {
        if (embeddedAssays._embedded) {
          return embeddedAssays._embedded.assays;
        } else {
          return [];
        }
      }),
      setUpdateResult
    );
  }

  /**
   * Sends any assay with a result to the server and returns the array of assays that the server said actually got
   * updated.
   */
  saveAssays(
    accession: StandardWorkspaceAccession,
    assays: Assay[],
    accept: boolean,
    panels: Panel[] = []
  ): Observable<Assay[]> {
    const payload: AssayUpdate[] = assays
      .map((assay) => {
        if (this.hasValue(assay) && !assay?.updatedResult?.previousResult) {
          return this.updateCommand(assay);
        } else if (this.hasValue(assay) && assay?.updatedResult?.previousResult) {
          return this.selectPreviousResultCommand(assay);
        } else {
          return null;
        }
      })
      .filter((result) => result !== null);

    // Update payload with assay comments and/or technically accepted status
    assays.forEach((assay: Assay) => {
      payload.push(this.commentCommand(assay));
      // We are passing all assays here, even if they are open. We are asking the backend to accept all the assays it
      // can, and rules may change the state of an assay to be completable even if we don't see it here currently.
      if (accept) {
        payload.push(this.acceptCommand(assay));
      }

      if (assay.repeatRequested) {
        if (assay.updatedResult && (assay.updatedResult.value || assay.updatedResult.noResult)) {
          throw new Error('Assay state invalid.');
        }
        payload.push(this.repeatCommand(assay));
      }
    });

    if (panels?.length) {
      panels.forEach((panel: Panel) => {
        payload.push(this.panelCommentCommand(panel));
      });
    }

    return this.http.post<AssayUpdateResponse>(accession._links.saveAssays.href, payload).pipe(
      switchMap(() => {
        return this.loadWorkspaceAssays(accession);
      })
    );
  }

  private acceptCommand(assay: Assay) {
    return {
      operationalId: assay.operationalId,
      value: assay.updatedResult.value,
      actionType: 'TECHNICALLY_ACCEPT',
    } as AssayUpdate;
  }

  private commentCommand(assay: Assay): AssayUpdate {
    return {
      operationalId: assay.operationalId,
      value: assay.comments && assay.comments.map((comment: Comment) => comment.id),
      actionType: 'UPDATE_ASSAY_COMMENTS',
    } as AssayUpdate;
  }

  private repeatCommand(assay: Assay) {
    return {
      operationalId: assay.operationalId,
      actionType: 'REPEAT',
    } as AssayUpdate;
  }

  private panelCommentCommand(panel: Panel) {
    return {
      operationalId: panel.operationalId,
      value: panel.comments && panel.comments.map((comment: Comment) => comment.id),
      actionType: 'UPDATE_PANEL_COMMENTS',
    } as any;
  }

  private updateCommand(assay: Assay) {
    return {
      operationalId: assay.operationalId,
      value: assay.updatedResult.noResult ? { noResult: true } : assay.updatedResult.value,
      actionType: 'UPDATE_RESULT',
    } as AssayUpdate;
  }

  private selectPreviousResultCommand(assay: Assay) {
    return {
      operationalId: assay.operationalId,
      value: assay.updatedResult.previousResult,
      actionType: 'SELECT_RESULT',
    } as AssayUpdateToPreviousResult;
  }

  getRangeDisplayByCount(intervals: ResultInterval[], count: number): string {
    if (intervals) {
      const result = intervals.find((interval) => {
        return count >= interval.low && (interval.high === undefined || count <= interval.high);
      });

      if (result) {
        return result.customerFacingText;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  hasComments(assay: Assay): boolean {
    return assay?.comments?.length > 0;
  }

  hasValue(assay: Assay): boolean {
    const value = assay?.updatedResult?.value;
    if (
      assay?.updatedResult?.noResult ||
      (Array.isArray(value) && value.length > 0) ||
      (!Array.isArray(value) && value)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getObservationDisplayTextByValue(values: DefinedTextValue[] | string[], value: string): string {
    let result;

    values.forEach((option) => {
      if (option?.code === value) {
        result = option?.display;
      }
    });

    if (!result) {
      result = '';
    }

    return result;
  }

  /**
   * @param onRepeat only called if the state of the assay is actually changed
   */
  repeat(assay: Assay, onRepeat?: () => void) {
    if (assay.canRepeat && !assay.repeatRequested) {
      assay.updatedResult.value = null;
      assay.updatedResult.noResult = false;
      assay.updatedResult.previousResult = null;
      assay.repeatRequested = true;
      if (onRepeat) {
        onRepeat();
      }
    }
  }

  /**
   * @param onNoResult only called if the state of the assay is actually changed.
   */
  noResult(assay: Assay, onNoResult?: () => void) {
    if (!assay.updatedResult?.noResult) {
      assay.updatedResult.value = null;
      assay.repeatRequested = false;
      assay.updatedResult.noResult = true;
      assay.updatedResult.previousResult = null;
      if (onNoResult) {
        onNoResult();
      }
    }
  }
}
