<div *ngIf="panel" class="panel" [ngClass]="{ canceled: panel.canceled }" [attr.data-cy-panel]="panel.testCode">
  <div class="panel-header" data-cy="panel-header">{{ panel.testCode }}</div>
  <div *ngIf="isExpanded" class="panel-body" data-cy="panel-body">
    <p>{{ panel.name }}</p>
    <div class="panel-comments">
      <lux-comments
        #panelComment
        data-cy="panel-comments"
        [allowAdd]="panel.canModify"
        [allowDelete]="panel.canModify"
        [lab]="lab"
        [commentsDataSource]="commentSearchUrl"
        [debounceDuration]="200"
        [context]="commentContext"
        [expandable]="true"
        [placeholder]="'LABELS.COMMENTS_SEARCH' | translate"
        (addComment)="onAddComment($event)"
        (removeComment)="onRemoveComment($event)"></lux-comments>
    </div>
  </div>
</div>
