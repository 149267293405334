import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-numeric-result-input',
  templateUrl: './numeric-result-input.component.html',
  styleUrls: ['./numeric-result-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericResultInputComponent),
      multi: true,
    },
  ],
})
export class NumericResultInputComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @ViewChild('input', { static: false }) input: ElementRef;

  @ViewChild('numericResultInputWrapper', { static: false })
  cmpWrapper: ElementRef;

  @Input()
  val = '';

  @Input()
  transformedValue = '';

  @Input()
  disabled = false;

  @Input()
  tabindex = 1;

  @Output()
  lostFocus = new EventEmitter<true>();

  @Output()
  resultChanged = new EventEmitter<true>();

  @Output() bulkSelect = new EventEmitter<void>();

  @Input()
  noResult: boolean;

  @Input()
  repeatRequested: boolean;

  @Output()
  noResultChange = new EventEmitter<boolean>();

  showError = false;
  statusSub: Subscription;
  valueSub: Subscription;
  clearInitialResult;

  control: AbstractControl;
  controlStatus: string;

  emptyResult: boolean;

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  get value() {
    return this.val;
  }

  set value(val: string) {
    this.val = this.removeTrailingDecimalSeparators(val);

    // If there's a value entered, then toggle noResult presentation to off
    if (this.val) {
      this.noResult = false;
      this.emptyResult = false;
    }

    // In any case emit noResult for form level effects
    this.noResultChange.emit(this.noResult);

    this.onChange(this.val);

    this.onTouched();
  }

  constructor(private injector: Injector) {}

  validate() {
    return true;
  }

  ngOnInit() {
    const model = this.injector.get(NgControl);
    this.control = model.control;

    this.valueSub = this.control.valueChanges.subscribe((val) => {
      if (this.clearInitialResult === undefined) {
        if (val) {
          this.clearInitialResult = true;
        } else {
          this.clearInitialResult = false;
        }
      }

      this.setErrorState();
    });

    this.statusSub = this.control.statusChanges.subscribe((status: string) => {
      this.controlStatus = status;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.setErrorState();
    }
  }

  ngOnDestroy(): void {
    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }

    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }

  writeValue(value: string) {
    if (value !== this.value) {
      // tslint:disable-next-line:no-string-literal
      const isEmptyResult = value && value['emptyResult'];

      this.value = isEmptyResult ? null : value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  setErrorState() {
    setTimeout(() => {
      const isInvalid =
        ((this.controlStatus === 'INVALID' && this.value !== null) || this.value === '') &&
        !this.noResult &&
        !this.emptyResult &&
        !this.repeatRequested;

      if (isInvalid) {
        let errors: ValidationErrors;
        errors = { inputError: true };
        this.control?.setErrors(errors);
        this.control?.markAsDirty();
      } else {
        this.control?.setErrors(null);
      }
    }, 0);
  }

  focusInput() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  handleInput($event) {
    this.clearInitialResult = false;
    this.writeValue($event.target.value);
  }

  handleBackspace() {
    if (this.clearInitialResult) {
      if (this.value) {
        this.writeValue('');
      }

      this.showError = false;
      this.clearInitialResult = false;
    }
  }

  handleFocusOut() {
    setTimeout(() => {
      if (document.activeElement === document.body || !this.cmpWrapper.nativeElement.contains(document.activeElement)) {
        this.input.nativeElement.value = this.removeTrailingDecimalSeparators(this.value);
        this.lostFocus.emit(true);
      }
    }, 0);
  }

  handleEnter($event) {
    if (this.disabled) {
      return;
    }

    if (!this.showError) {
      this.input.nativeElement.value = this.removeTrailingDecimalSeparators(this.value);
      if (this.input.nativeElement.value) {
        this.resultChanged.emit(true);
      }
    }
  }

  removeTrailingDecimalSeparators(val: string) {
    if (val) {
      const lastCharacter = val[val.length - 1];

      if (val.length > 1 && (lastCharacter === ',' || lastCharacter === '.')) {
        val = val.substring(0, val.length - 1);
      }
    }

    return val;
  }

  handleBulkSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    this.bulkSelect.emit();
  }
}
