import { Pipe, PipeTransform } from '@angular/core';
import { Assay } from '../interfaces/assay.interface';

@Pipe({
  name: 'isDefinedText',
  standalone: true,
})
export class IsDefinedTextPipe implements PipeTransform {
  transform(value: Assay): boolean {
    return value?.resultDefinition?.valueType === 'DEFINED_TEXT';
  }
}
