import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { DefinedTextValue, ShortCodeMapping } from '@lims-common-ux/lux';
import { AssayService } from '../../assay.service';
import { ResultInterval } from '../../../../interfaces/assay.interface';

export interface SemiQuantitativeResultComboValue {
  typeCode: string;
  isNoneSeen: boolean;
  count?: number;
  preventQuantification?: boolean;
}

export enum SemiQuantComboError {
  NO_MATCHING_OBSERVATIONS,
  QUANTIFICATION_REQUIRED,
  QUANTIFICATION_NOT_ALLOWED,
  VALUE_REQUIRED,
  OUT_OF_RANGE,
}

/**
 * This component handles input where there are observations with optional quantities. The format of the input is
 * <SHORT_CODE><AMOUNT_SEEN> where the short code is defined by the input `shortCodes` and amountSeen is an integer
 * value.
 */
@Component({
  selector: 'app-semi-quantitative-result-combo',
  templateUrl: './semi-quantitative-result-combo.component.html',
  styleUrls: ['./semi-quantitative-result-combo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SemiQuantitativeResultComboComponent),
      multi: true,
    },
  ],
})
export class SemiQuantitativeResultComboComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input()
  resultOptions: DefinedTextValue[] = [];

  @Input()
  intervals: ResultInterval[] = [];

  @Input()
  valueCombinations: { [key: string]: ResultInterval[] };

  @Input()
  placeholder = '';

  _noResult = false;

  @Input()
  set noResult(val: boolean) {
    this._noResult = val;

    if (this._noResult && this.input) {
      setTimeout(() => {
        this.resetErrorState();
        this.input.nativeElement.value = '';
        this.onChange(null);
      }, 0);
    }
  }

  get noResult() {
    return this._noResult;
  }

  @Output()
  noResultChange = new EventEmitter<boolean>();
  @Output()
  noneSeen = new EventEmitter();

  @Input()
  hidden = true;

  @Input()
  tabindex = 1;

  // tslint:disable-next-line:no-input-rename
  @Input('value')
  val: SemiQuantitativeResultComboValue[] = null;

  @Input()
  initialValue: SemiQuantitativeResultComboValue[] = null;

  @Input()
  name;

  @Input()
  shortCodes: ShortCodeMapping;

  @Input()
  showPrefix = false;

  @Input()
  disabled: boolean;

  @Output() bulkSelect = new EventEmitter<void>();

  @ViewChild('resultInput', { static: false })
  input: ElementRef;

  @ViewChild('wrapper', { static: false })
  cmpWrapper: ElementRef;

  @ViewChildren('resultsListItem')
  resultsListItems!: QueryList<ElementRef>;

  @ViewChildren('deleteIcons')
  deleteIcons!: QueryList<ElementRef>;

  filteredResultOptions: DefinedTextValue[] = [];
  amountSeen: number;
  control: AbstractControl;
  initialFocus = false;
  private maxCharacters = 9;

  @Input()
  editMode = false;

  @Input()
  repeatRequested: boolean;

  // Helps the template decide what error message to display
  displayError: SemiQuantComboError = null;
  // Just here to expose the enum to the template
  get errorTypes(): typeof SemiQuantComboError {
    return SemiQuantComboError;
  }

  get value() {
    return this.val;
  }

  set value(val: SemiQuantitativeResultComboValue[]) {
    // Values returned from the backend (after save, etc.) need a flag set
    if (val && val.length > 0) {
      val.forEach((value) => {
        this.resultOptions.forEach((option) => {
          if (option.code === value.typeCode) {
            value.isNoneSeen = option.noneSeen;
          }
        });
      });
    }

    this.val = val;

    if (this.val?.length > 0) {
      this.noResult = false;
      this.noResultChange.emit(this.noResult);
    }
    this.updateDisplay();
  }

  constructor(
    private assayService: AssayService,
    private injector: Injector,
    @Inject('Document')
    private document: Document
  ) {}

  ngOnInit() {
    const model = this.injector.get(NgControl);
    this.control = model.control;
    this.filteredResultOptions = this.resultOptions;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes?.noResult?.currentValue || changes?.repeatRequested?.currentValue) && this.input) {
      setTimeout(() => {
        this.resetErrorState();
        this.input.nativeElement.value = '';
      }, 0);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  writeValue(value: SemiQuantitativeResultComboValue | SemiQuantitativeResultComboValue[]) {
    // handles no result rewrite
    if (!value && this.value) {
      this.value = null;
      return;
    }

    // True when the assay has existing values and form is populating the first time.
    if (value && Array.isArray(value)) {
      this.value = value;
      return;
    }
    if (value) {
      const existing = this.value ? this.value : [];
      const newVal = value as SemiQuantitativeResultComboValue;
      if (!newVal.isNoneSeen) {
        // we filter out any existing values that match the new value we also remove any existing None Seen
        const filteredVal = existing.filter((existingValue: SemiQuantitativeResultComboValue) => {
          return newVal.typeCode !== existingValue.typeCode && !existingValue.isNoneSeen;
        });
        filteredVal.unshift(newVal);
        this.value = filteredVal;
      } else {
        this.value = [newVal];
      }
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  close($event = null) {
    if (!this.hidden && $event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
    }
    this.hidden = true;
  }

  open() {
    this.hidden = false;
  }

  isClosed() {
    return this.hidden;
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  verifyCompleteEntry() {
    if (this.input.nativeElement.value) {
      this.input.nativeElement.click();

      requestAnimationFrame(() => {
        this.initialFocus = false;
        this.close();
        this.assignError(SemiQuantComboError.VALUE_REQUIRED);
      });
    }
  }

  handleFocusOut($event) {
    this.onTouched();
    if (this.control.dirty && !this.control.errors && this.value) {
      this.onChange(this.value);
    }
    setTimeout(() => {
      if (document.activeElement === document.body || !this.cmpWrapper.nativeElement.contains(document.activeElement)) {
        this.initialFocus = false;
        this.close();
      }
    }, 0);
  }

  // alt + e or double clicking puts this component into edit mode
  // which allows us to remove Result values
  toggleEditMode($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.disabled) {
      return;
    }
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.focusInput();
    }
  }

  /**
   * This is responsible for setting several error states while the user is entering text into the input field. We
   * do this here instead of a validator because the model does not change until a value has been selected, and these
   * are all presented before that happens.
   *
   * VALUE_REQUIRED, if the user has removed all the saved values and searching for another result, this error should be
   * set until a value is selected.
   * NO_MATCHING_OBSERVATIONS, when the user has entered text into the input, but no short codes match what was entered.
   * QUANTIFICATION_NOT_ALLOWED, when the user enters a valid short code and an amount seen for an option that does not
   * have any intervals defined for it.
   */
  handleInput($event) {
    const value = $event.target.value.trim().toUpperCase();
    this.filteredResultOptions = [];
    this.amountSeen = null;
    // clear errors, they are all verified after input is parsed.
    this.assignError(null);

    if (!value || value?.trim() === '') {
      this.filteredResultOptions = this.resultOptions;
    } else {
      let enteredText;
      let selectedShortCode;

      if (value === '0') {
        enteredText = value;
      } else {
        enteredText = value.replace(/\d+/, '');
      }

      selectedShortCode = this.findShortCode(enteredText);
      this.amountSeen = this.getAmountSeen(value, selectedShortCode, enteredText);
      this.filteredResultOptions = this.optionsForShortCode(selectedShortCode);
    }

    this.verifyInput();

    // VALUE_REQUIRED shouldn't prevent the list from displaying as the user is likely changing/adding inputs at
    // this point.
    if (this.displayError === null || this.displayError === SemiQuantComboError.VALUE_REQUIRED) {
      this.open();
    } else {
      this.close();
    }
    this.control.markAsDirty();
  }

  private verifyInput() {
    if (this.filteredResultOptions.length === 0 || this.isInvalidAmountSeen()) {
      this.assignError(SemiQuantComboError.NO_MATCHING_OBSERVATIONS);
    } else if (this.amountSeen !== null && this.amountSeen.toString().length > this.maxCharacters) {
      this.assignError(SemiQuantComboError.OUT_OF_RANGE);
    } else if (this.amountSeen !== null && this.shouldPreventQuantification(this.filteredResultOptions[0])) {
      this.assignError(SemiQuantComboError.QUANTIFICATION_NOT_ALLOWED);
    } else if (this.isInvalidRange(this.filteredResultOptions[0], this.amountSeen)) {
      this.assignError(SemiQuantComboError.NO_MATCHING_OBSERVATIONS);
    } else if (this.checkIfValueRequired()) {
      this.assignError(SemiQuantComboError.VALUE_REQUIRED);
    }
  }

  /**
   * @return true if the value is set and is NOT a valid integer. if the number is less then zero, an invalid character,
   * or a number we dont like (1.0) it is considered invalid
   */
  private isInvalidAmountSeen(): boolean {
    return (
      this.amountSeen !== null &&
      (this.amountSeen <= 0 || Number.isNaN(this.amountSeen) || this.amountSeen.toString().indexOf('.') !== -1)
    );
  }

  private getAmountSeen(value: string, selectedShortCode: string | null, enteredText: string): number | null {
    let amountSeen: number = null;
    // confirm there was something after the shortcode none visible shortcode (0) doesnt get a range
    if (selectedShortCode != null && value.length > selectedShortCode.length && enteredText !== '0') {
      // get a string representation of the remaining characters
      const amountSeenText = value.substring(selectedShortCode.length, selectedShortCode.length + value.length);
      // convert the string into a number
      amountSeen = Number(amountSeenText);
    }
    return amountSeen;
  }

  /**
   * This will really only happen if the user enters 0, and none-seen is not a valid option for this particular
   * assay, or if GDOS is mis-configured and doesn't have a catch all value like >50 defined.
   */
  private isInvalidRange(definedText: DefinedTextValue, newAmountSeen: number): boolean {
    const range = this.getRangeDisplayByCount({
      typeCode: definedText.code,
      count: newAmountSeen,
      isNoneSeen: false,
    });

    return range === '';
  }

  private findShortCode(enteredText): string | null {
    let code: string = null;
    for (const shortCode in this.shortCodes) {
      if (shortCode === enteredText) {
        code = shortCode;
        break;
      }
    }
    return code;
  }

  assignError(error: SemiQuantComboError | null) {
    const newErrorState = {
      inputError: error === SemiQuantComboError.NO_MATCHING_OBSERVATIONS,
      outOfRange: error === SemiQuantComboError.OUT_OF_RANGE,
      removedSavedValues: error === SemiQuantComboError.VALUE_REQUIRED,
      enteredUnneededQuantification: error === SemiQuantComboError.QUANTIFICATION_NOT_ALLOWED,
      quantificationRequired: error === SemiQuantComboError.QUANTIFICATION_REQUIRED,
    };

    if (error === null) {
      this.control.setErrors(null);
    } else {
      this.control.setErrors(newErrorState);
    }
    this.displayError = error;
  }

  resetErrorState() {
    requestAnimationFrame(() => {
      this.assignError(null);
    });
  }

  handleEnter($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.disabled) {
      return;
    }

    if (this.isClosed() && !this.input.nativeElement.value) {
      this.filteredResultOptions = this.resultOptions;
      this.open();
    } else if (!this.isClosed()) {
      if (this.filteredResultOptions.length) {
        this.selectOption(this.filteredResultOptions[0], $event);
      } else {
        this.close();
      }
    } else if (this.isClosed() && this.input.nativeElement.value && $event?.target?.value) {
      this.handleInput($event);
    }
  }

  handleFocus($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (!this.initialFocus) {
      this.initialFocus = true;
    }

    if (!this.value?.length && this.initialFocus && !this.control.errors && !this.disabled) {
      this.open();
    }
  }

  inputHasFocus() {
    return this.input?.nativeElement === this.document.activeElement;
  }

  handleArrowDown($event) {
    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      this.filteredResultOptions.push(this.filteredResultOptions.splice(0, 1)[0]);

      return false;
    }
  }

  removeResultValue(index: number) {
    if (!this.editMode) {
      return;
    }

    this.value.splice(index, 1);
    if (!this.value.length) {
      this.value = null;
    }

    if (this.checkIfValueRequired()) {
      this.assignError(SemiQuantComboError.VALUE_REQUIRED);
    } else {
      this.onChange(this.value);
      if (this.value === null || this.value?.length === 0) {
        this.control.markAsPristine();
      }
    }

    // we want focus to be on next Result Value, otherwise set focus to the input when removing
    setTimeout(() => {
      let nextEleToFocus: ElementRef;
      if (!this.value?.length) {
        nextEleToFocus = this.input;
      } else {
        const nextIndex = index === this.value.length ? this.value.length - 1 : index;
        nextEleToFocus = this.deleteIcons.toArray()[nextIndex];
      }

      nextEleToFocus.nativeElement.focus();
    }, 0);
  }

  private checkIfValueRequired(): boolean {
    return !this.value?.length && this.initialValue?.length && !this.noResult;
  }

  handleArrowUp($event) {
    if (!this.isClosed()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      const lastItem: DefinedTextValue = this.filteredResultOptions.pop();
      this.filteredResultOptions.unshift(lastItem);
    }
  }

  getShortCodeByValue(val: string) {
    let prop;
    let match = '';

    for (prop in this.shortCodes) {
      if (val === this.shortCodes[prop]) {
        match = prop;

        break;
      }
    }

    return match;
  }

  // returns a boolean indicating if a DefinedTextValue is not allowed to be quantified
  shouldPreventQuantification(definedValue: DefinedTextValue) {
    return (
      !this.valueCombinations[definedValue.code] ||
      (Array.isArray(this.valueCombinations[definedValue.code]) &&
        this.valueCombinations[definedValue.code].length === 0)
    );
  }

  requiresQuantification(definedValue: DefinedTextValue) {
    return (
      !definedValue.noneSeen &&
      Array.isArray(this.valueCombinations[definedValue.code]) &&
      this.valueCombinations[definedValue.code].length > 0
    );
  }

  selectOption(definedValue: DefinedTextValue, $event: Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    if (this.disabled) {
      return;
    }
    this.editMode = false;
    const requiresQuantification = this.requiresQuantification(definedValue);
    if (requiresQuantification && !this.amountSeen) {
      this.focusInput();
      let inputVal = this.input.nativeElement.value;
      if (inputVal === '') {
        inputVal = this.getShortCodeByValue(definedValue.code);
      }
      this.input.nativeElement.value = inputVal;
      this.assignError(SemiQuantComboError.QUANTIFICATION_REQUIRED);
      this.close();
      return;
    }

    if (this.amountSeen) {
      this.writeValue({
        typeCode: definedValue.code,
        isNoneSeen: definedValue.noneSeen,
        count: this.amountSeen,
      });
    } else {
      this.writeValue({
        typeCode: definedValue.code,
        isNoneSeen: definedValue.noneSeen,
      });
    }

    this.amountSeen = null;
    this.close();
    this.updateDisplay();
    this.filteredResultOptions = this.resultOptions;
    this.focusInput();
    this.assignError(null);
    this.onChange(this.value);
    if (this.value[0].isNoneSeen) {
      this.noneSeen.emit();
    }
  }

  getObservationDisplayTextByValue(value: string): string {
    return this.assayService.getObservationDisplayTextByValue(this.resultOptions, value);
  }

  getRangeDisplayByCount(item: SemiQuantitativeResultComboValue) {
    if (item.count) {
      return this.assayService.getRangeDisplayByCount(this.valueCombinations[item.typeCode], item.count);
    }
    return false;
  }

  handleSpace($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    return false;
  }

  private updateDisplay() {
    this.input.nativeElement.value = '';
    this.control.setErrors(null);
  }

  private validShortCode(shortCode: string): boolean {
    // the length check is if we have a short code, but do not have a matching option. This will happen sometimes
    // with species specific tests where the observations change between species.
    return shortCode && this.optionsForShortCode(shortCode).length > 0;
  }

  private optionsForShortCode(selectedShortCode): DefinedTextValue[] {
    return this.resultOptions.filter((definedText) => {
      return definedText.code === this.shortCodes[selectedShortCode];
    });
  }

  handleBulkSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    this.bulkSelect.emit();
  }
}
