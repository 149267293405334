import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, Observable, of, map, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { WorkspaceAccessionService } from './workspace-accession.service';
import { AppStateService } from '../../app-state.service';
import { AssayService } from '../assay/assay.service';
import { LoadedAccessionInfo } from './loaded-accession.class';
import { AuditService, GlobalErrorHandlerService, Workspace } from '@lims-common-ux/lux';

@Injectable({ providedIn: 'root' })
export class AccessionResolver {
  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private globalErrorHandler: GlobalErrorHandlerService,
    private workspaceAccessionService: WorkspaceAccessionService,
    private assayService: AssayService,
    private auditService: AuditService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LoadedAccessionInfo> {
    const accessionId = route.paramMap.get('accession');
    const loadedAccession = new LoadedAccessionInfo();
    this.appStateService.workspaceQueueEmptyMessageVisible = false;
    this.appStateService.queueWorkspace = false;

    return this.workspaceAccessionService.loadAccession(this.appStateService.accessionLink, accessionId).pipe(
      catchError((err) => {
        this.router.navigate(['/', 'lims-results', this.appStateService.lab.id]).then(() => {
          this.globalErrorHandler.handleError(err);
        });
        return EMPTY;
      }),
      switchMap((headerAccession) => {
        const workspace = route.parent.data?.workspace as Workspace;
        // If we don't have a workspace available from the list of the workspaces in the lab, we will fall back to
        // the workspaces available on the accession itself. This means we are looking at the accession in a lab that
        // is not the originating lab, and is not an expected performing lab for any assay.
        if (!workspace) {
          const workspaceId = route.parent.paramMap.get('workspace');
          this.appStateService.currentWorkspace = headerAccession._embedded.workspaces.find(
            (wrk) => wrk.id === workspaceId && wrk.workspaceType === 'STANDARD_WORKSPACE'
          );
          if (!this.appStateService.currentWorkspace) {
            this.router.navigate(['/', 'lims-results', this.appStateService.lab.id]).then(() => {
              this.globalErrorHandler.handleError(
                new Error(`Could not find standard workspace ${workspaceId} in accession ${accessionId}`)
              );
            });
            return EMPTY;
          }
        }
        return of(headerAccession);
      }),
      switchMap((headerAccession) => {
        return this.workspaceAccessionService
          .loadWorkspaceAccession(headerAccession, this.appStateService.currentWorkspace)
          .pipe(
            tap((workspaceAccession) => {
              this.appStateService.accessionHeader = headerAccession;
              this.appStateService.accession = workspaceAccession;

              loadedAccession.resultAccession = this.appStateService.accessionHeader;
              loadedAccession.accession = this.appStateService.accession;
            })
          );
      }),
      // Need workspace accession to be loaded before we get panels and assays. Panels and Assays can also
      // be loaded concurrently
      switchMap(() =>
        combineLatest([
          this.assayService.loadWorkspaceAssays(loadedAccession.accession),
          this.workspaceAccessionService.loadPanels(loadedAccession.accession),
        ])
      ),
      // finally, after assays and panels are loaded, finish by returning the LoadedAccesionInfo with everything
      // populated
      map((assaysAndPanels) => {
        loadedAccession.assays = assaysAndPanels[0];
        loadedAccession.panels = assaysAndPanels[1];
        this.auditService.fireAccessionLoaded(accessionId);
        return loadedAccession;
      })
    );
  }
}
