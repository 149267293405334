<div
  [class.spot-form--error]="showError || showRemovedSavedError"
  #wrapper
  class="multi-result-value-wrapper"
  (focusout)="handleFocusOut($event)">
  <input
    #resultInput
    autocomplete="off"
    (focus)="handleFocus($event)"
    (focusout)="verifyCompleteEntry()"
    (input)="handleInput()"
    (keydown.alt.e)="toggleEditMode($event)"
    (keydown.arrowdown)="handleArrowDown($event)"
    (keydown.arrowup)="handleArrowUp($event)"
    (keydown.enter)="handleEnter($event)"
    (keydown.escape)="close($event)"
    (keydown.alt.space)="handleBulkSelect($event)"
    [class.active]="!hidden"
    [attr.aria-disabled]="disabled"
    [readonly]="disabled"
    [name]="name"
    [placeholder]="placeholder"
    [tabindex]="tabindex"
    class="spot-form__input"
    data-cy="result-input semi-quantitative-combo-input"
    spellcheck="false"
    type="text" />

  <span class="spot-form__select-open-indicator">
    <svg
      class="spot-icon search-icon"
      aria-labelledby="title"
      [class.has-error]="showError || showRemovedSavedError"
      [class.has-focus]="initialFocus">
      <use xlink:href="../../assets/icons/spot_icons.svg#search"></use>
    </svg>
  </span>
  <div *ngIf="showError" class="spot-form__field-error" role="alert" data-cy="semi-quantitative-result-input-error">
    <span class="spot-form__field-error-text" data-cy="result-value-error"
      >{{ 'ERRORS_AND_FEEDBACK.NO_MATCHES' | translate }}
    </span>
  </div>
  <div
    *ngIf="showRemovedSavedError && !showError"
    class="spot-form__field-error"
    role="alert"
    data-cy="semi-quantitative-result-save-error">
    <span class="spot-form__field-error-text" data-cy="result-value-error"
      >{{ 'ERRORS_AND_FEEDBACK.MUST_ENTER_RESULT_VALUE' | translate }}
    </span>
  </div>

  <div
    [hidden]="hidden"
    class="search-results-wrapper"
    [class.has-error]="showRemovedSavedError || showError"
    data-cy="defined-text-results">
    <ng-container *ngFor="let item of filteredResultOptions | slice: 0 : 4; let index = index; let last = last">
      <button
        #resultsListItem
        tabindex="-1"
        luxLayout="row"
        luxLayoutAlign="start start"
        class="result-list-item"
        data-cy="semi-quantitative-combo-item"
        type="button"
        [class.default-selection]="index === 0"
        (click)="selectOption(item, $event)"
        [name]="item.code">
        <span
          luxFlex="50px"
          *ngIf="getShortCodeByValue(item.code) && showPrefix"
          (click)="selectOption(item, $event)"
          class="prefix"
          >{{ getShortCodeByValue(item.code) }}</span
        >
        <span luxFlex class="content">{{ item.display }}</span>
      </button>
    </ng-container>
  </div>

  <div *ngIf="value?.length > 0 && !disabled" class="spot-form__microcopy">
    <div>
      {{ 'HOT_KEY_INSTRUCTIONS.EDIT' | translate }}
    </div>
  </div>
</div>

<div
  *ngIf="value?.length > 0"
  (dblclick)="toggleEditMode($event)"
  class="selections"
  data-cy="semi-quantitative-combo-selections"
  luxLayout="column">
  <ng-container *ngFor="let item of value; let index = index; let first = first">
    <div
      class="selection"
      *ngIf="item"
      data-cy="multi-result-selection"
      luxFlex
      luxLayout="row"
      luxLayoutAlign="space-between start">
      <div luxFlex data-cy="result-value">{{ getObservationDisplayTextByValue(item) }}</div>
      <span
        #deleteIcons
        (click)="removeResultValue(item, index)"
        (keydown.enter)="removeResultValue(item, index)"
        (keydown.alt.e)="toggleEditMode($event)"
        tabindex="1"
        luxFlex="30px"
        luxLayoutAlign="start end"
        *ngIf="editMode"
        class="result-delete-icon">
        <svg data-cy="result-delete-icon" class="spot-icon" aria-labelledby="title">
          <use xlink:href="../../assets/icons/spot_icons.svg#delete"></use>
        </svg>
      </span>
    </div>
  </ng-container>
</div>
