import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appSemiQuantitativeValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SemiQuantitativeValueDirective,
      multi: true,
    },
  ],
})
export class SemiQuantitativeValueDirective implements Validator, OnChanges {
  // This is a 'shared' input with the component to get the initial state.
  @Input('appSemiQuantitativeValue') initialValue;
  private initialVal: string;

  @Input()
  noResult: boolean;

  @Input()
  repeatRequested: boolean;

  onChange: () => void;

  private maxCharacters = 9;

  ngOnChanges(changes: SimpleChanges) {
    if (this.onChange && (changes.noResult || changes.repeatRequested)) {
      window.requestAnimationFrame(() => {
        this.onChange();
      });
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    // Escape hatch, no errors present when their is no result present
    if (this.noResult || this.repeatRequested) {
      return null;
    }

    const val = control.value;

    if (this.initialVal === undefined) {
      this.initialVal = this.initialValue;
    }

    if (val) {
      if (
        isNaN(val) ||
        val < 0 ||
        !Number.isInteger(parseFloat(val)) ||
        val.indexOf(',') !== -1 ||
        val.indexOf('.') !== -1
      ) {
        return { error: true };
      } else if (val.toString().length > this.maxCharacters) {
        return { outOfRange: true };
      } else {
        return null;
      }
    } else {
      if (val === this.initialVal) {
        control.markAsPristine();

        return null;
      } else if (this.initialValue) {
        return { error: true };
      } else {
        return null;
      }
    }
  }
}
