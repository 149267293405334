import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { StringUtilService } from '@lims-common-ux/lux';

@Directive({
  selector: '[appNumericValueValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NumericValueValidatorDirective,
      multi: true,
    },
  ],
})
export class NumericValueValidatorDirective implements Validator {
  constructor(private strService: StringUtilService) {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    let val = control.value;

    if (val !== '') {
      val = this.strService.toNormalizedResultValue(val);
    }

    if (val === null && control.dirty) {
      return { error: true };
    } else {
      if (val === '') {
        control.markAsPristine();
      }

      return null;
    }
  }
}
