import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkspaceComponent } from './workspace/workspace.component';
import { AccessionComponent } from './workspace/accession/accession.component';
import { AccessionResolver } from './workspace/accession/accession.resolver';
import { LabResolver } from './lab/lab.resolver';
import { WorkspaceResolver } from './workspace/workspace.resolver';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { LabComponent } from './lab/lab.component';
import { QueueAccessionResolver } from './workspace/workspace-queue/queue-accession.resolver';
import { EmptyQueueComponent } from './workspace/workspace-queue/empty-queue/empty-queue.component';
import { EmptyQueueResolver } from './workspace/workspace-queue/empty-queue/empty-queue.resolver';

const routes: Routes = [
  {
    path: `lims-results/:lab`,
    component: LabComponent,
    resolve: {
      lab: LabResolver,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'workspaces/:workspace',
        component: WorkspaceComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          workspace: WorkspaceResolver,
        },
        children: [
          {
            path: 'accessions/:accession',
            component: AccessionComponent,
            canDeactivate: [CanDeactivateGuard],
            // Allows for searching and refreshing the same accession
            runGuardsAndResolvers: 'always',
            resolve: {
              accessionInfo: AccessionResolver,
            },
          },
          {
            path: 'queue',
            component: AccessionComponent,
            canDeactivate: [CanDeactivateGuard],
            runGuardsAndResolvers: 'always',
            resolve: {
              accessionInfo: QueueAccessionResolver,
            },
          },
          {
            path: 'empty-queue',
            runGuardsAndResolvers: 'always',
            component: EmptyQueueComponent,
            resolve: {
              emptyQueue: EmptyQueueResolver,
            },
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: `lims-results/NOV` },
  { path: '', redirectTo: `lims-results/NOV`, pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
