import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel.component';
import { CommentsModule } from '@lims-common-ux/lux';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [TranslateModule.forChild({}), CommonModule, CommentsModule],
  declarations: [PanelComponent],
  exports: [PanelComponent],
})
export class PanelModule {}
