import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DefinedTextResultComponent, DefinedTextValue, FeaturesService, Lab } from '@lims-common-ux/lux';
import {
  Assay,
  AssayStatus,
  isDefinedTextValue,
  ResultDefinition,
  ResultInterval,
} from '../../../interfaces/assay.interface';
import { ControlContainer, NgForm } from '@angular/forms';
import { AppStateService } from '../../../app-state.service';
import { NumericResultInputComponent } from './numeric-result-input/numeric-result-input.component';
import { SemiQuantitativeResultComboComponent } from './semi-quantitative-result-combo/semi-quantitative-result-combo.component';
import { SemiQuantitativeResultInputComponent } from './semi-quantitative-result-input/semi-quantitative-result-input.component';
import { FreeTextComponent } from './free-text/free-text.component';
import { AssayCardService } from './assay-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-assay-card',
  templateUrl: './assay-card.component.html',
  styleUrls: ['./assay-card.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssayCardComponent implements AfterViewInit {
  @ViewChild('cardBody', { static: false })
  body: ElementRef;

  @ViewChild('cardWrapper', { static: false })
  cardWrapper: ElementRef;

  @ViewChild('displayValueLink', { static: false })
  displayValueLink: ElementRef;

  @ViewChild('bulkSelectCheckbox', { static: false })
  bulkSelectCheckbox: ElementRef;

  @ViewChild('resultInput', { static: false })
  resultInput:
    | NumericResultInputComponent
    | DefinedTextResultComponent
    | SemiQuantitativeResultComboComponent
    | SemiQuantitativeResultInputComponent
    | FreeTextComponent;

  @Input()
  assay: Assay;

  @Input()
  firstCard: boolean;

  @Input()
  lastCard: boolean;

  @Input()
  lab: Lab;

  @Output()
  valueChange = new EventEmitter<true>();

  presentationClass = '';
  cardWrapperPresentationClass = '';

  showClinicalReference: boolean;

  editMode = false;
  showSampleAssociation = false;
  chemAsStandardWorkspaceFeatureIsOn = false;
  bulkSelectedAssays$ = this.appStateService.bulkSelectedAssays$;

  constructor(
    private appStateService: AppStateService,
    private assayCardService: AssayCardService,
    private featuresService: FeaturesService
  ) {
    this.chemAsStandardWorkspaceFeatureIsOn = this.featuresService.hasFeature('CHEM_AS_STANDARD_WORKSPACE');
    this.appStateService.currentSample$.pipe(takeUntilDestroyed()).subscribe((sample) => {
      if (
        this.assay &&
        sample?.testAssociations.includes(this.assay.testCode) &&
        this.chemAsStandardWorkspaceFeatureIsOn
      ) {
        this.showSampleAssociation = true;
      } else {
        this.showSampleAssociation = false;
      }
    });
  }

  ngAfterViewInit() {
    // Different result inputs may transform entered values, here we are setting a presentation value for use
    // in assay details and other areas of the app where we need access to the transformed result input value on load
    if (this.resultInput?.input?.nativeElement) {
      if (this.assay && this.assay.presentationValue !== this.assay.result.value) {
        const presentationValue = this.modifyPresentationValue(
          this.assay.result.value,
          this.assay.resultDefinition,
          this.assay.result.interval
        );
        this.assay.presentationValue = presentationValue; // TODO: remove once CHEM_AS_STANDARD_WORKSPACE is live LG-11086

        this.assay.result.presentationValue = presentationValue;
        this.assay.allResults?.forEach((result) => {
          result.presentationValue = this.modifyPresentationValue(
            result.value,
            this.assay.resultDefinition,
            result.interval
          );
        });
      }

      this.resultInput.control?.valueChanges.subscribe((newValue) => {
        if (newValue) {
          // we need to unset this incase some clicks "repeat", then goes back and adds a result value.
          this.assay.repeatRequested = false;
        }
        this.setCardPresentation();
      });
    }
  }

  /* EVALUATE AN ASSAY CARD ASSAY STATUS, RESULT, UPDATED RESULT, AND THE ASSAY CARD RESULT INPUT CONTROL */
  /* TO DETERMINE CARD WRAPPER PRESENTATION. THE PRESENTATION CLASS STRING IS DIRECTLY TIED TO CSS STYLE */
  /* DEFINITIONS. ASSIGNING CSS CLASSES THIS WAY CAN BE VERY FAST AND AVOIDS COMPLICATIONS WITH ANGULAR */
  /* CHANGE DETECTION AND SCREEN PAINTS WITHOUT RELYING ON AN OBSERVABLE. */
  /* NOTE: THERE IS NO EXPLICIT 'SELECTED' STATE. SELECTED STYLE PRESENTATION IS A FUNCTION */

  /* OF FOCUS-WITHIN AND APPSTATE CURRENTASSAY */
  getCardWrapperPresentation() {
    const classes = [];
    const cardWrapperClasses = [];

    if (this.assay) {
      // performed-at-different-lab
      if (
        this.assay.status === AssayStatus.OPENED &&
        this.lab.id !== this.assay.expectedPerformingLab &&
        this.assay?.updatedResult.value === null
      ) {
        classes.push('performed-at-different-lab');
      }

      // ALERT
      if (this.assay.status === AssayStatus.TECHNICIAN_REVIEW) {
        classes.push('ALERT');
      }

      // NO_RESULT
      if (!this.assay.updatedResult?.value && this.assay.updatedResult?.noResult) {
        classes.push('NO_RESULT');
      }

      // REPEAT_REQUESTED
      if (
        (this.assay.status === AssayStatus.REPEAT_REQUESTED || this.assay.repeatRequested) &&
        !this.assay.updatedResult?.noResult &&
        !this.assay.updatedResult?.value
      ) {
        classes.push('REPEAT_REQUESTED');
      }

      // HAS_CHANGES
      if (!this.resultInput.control.errors && this.resultInput.control.valid && this.resultInput.control.dirty) {
        classes.push('HAS_CHANGES');
      }

      // CANCELED
      if (this.assay.status === AssayStatus.CANCELED) {
        classes.push('CANCELED');
      }

      // TECHNICALLY_ACCEPTED
      if (this.assay.status === AssayStatus.TECHNICALLY_ACCEPTED) {
        classes.push('TECHNICALLY_ACCEPTED');
      }

      // RELEASED
      if (this.assay.status === AssayStatus.RELEASED) {
        classes.push('RELEASED');
      }

      // SAVED
      const savedAssays = this.assayCardService.getSavedAssays();
      if (savedAssays) {
        const hasSavedChanges = savedAssays.find((savedAssay) => savedAssay.name === this.assay.name);
        if (hasSavedChanges && classes.indexOf('TECHNICALLY_ACCEPTED') < 0 && classes.indexOf('RELEASED') < 0) {
          classes.push('SAVED');
        }
      }

      if (this.assay.status === AssayStatus.OPENED && this.assay.resultRequired === 'NON_ESSENTIAL') {
        classes.push('NON_ESSENTIAL');
      }

      // ATTENTION
      if (classes.indexOf('SAVED') < 0 && this.assay.status === AssayStatus.OPENED) {
        classes.push('ATTENTION');
      }

      // HAS_TRANSFORMED_VALUE
      if (this.assay.result.transformedValue) {
        classes.push('HAS_TRANSFORMED_VALUE');
      }

      // STACKED
      if (this.assay.allResults?.length > 1 && this.chemAsStandardWorkspaceFeatureIsOn) {
        cardWrapperClasses.push('STACKED');
      }
    }
    this.presentationClass = classes.join(' ');
    this.cardWrapperPresentationClass = cardWrapperClasses.join(' ');
  }

  modifyPresentationValue(value: any, resultDefinition?: ResultDefinition, interval?: ResultInterval): any {
    /* result-value on server side is of any type, however even with generic we've been using 'any'.
      Using generic with proper typing makes it complicated to support case when interval.display (string) being returned.
     Instead of using casting switched to just any. */
    // semi-quant combo, and defined text multi will have an array of values
    if (Array.isArray(value)) {
      return this.modifyPresentationValueMultiSelect(value, resultDefinition);
    } else if (value) {
      return this.modifyPresentationValueString(value, resultDefinition, interval);
    } else {
      return value;
    }
  }

  private modifyPresentationValueMultiSelect(value: any[], resultDefinition?: ResultDefinition): any[] {
    return value.map((resultValue) => {
      let currentVal;
      if (typeof resultValue === 'string') {
        // defined text multi
        currentVal = resultValue;
        // None-Seen indicators, want to treat these differently than actual selected values
      } else if (resultValue.typeCode === '' || resultValue.typeCode === '0' || resultValue.typeCode === 0) {
        currentVal = resultValue.typeCode;
      } else {
        // semi-quant combo
        currentVal = resultValue;
      }

      if (resultDefinition?.types) {
        // look up the translated values from the supplied result definitions.
        const possibleValue = resultDefinition.types.find(
          (type) => isDefinedTextValue(type) && (type.code === currentVal || type.code === currentVal?.typeCode)
        );
        if (!possibleValue) {
          return resultValue;
        }
        if (currentVal.interval) {
          return Object.assign({ display: (possibleValue as DefinedTextValue)?.display }, currentVal);
        } else {
          return {
            display: (possibleValue as DefinedTextValue)?.display,
            noneSeen: !!currentVal?.isNoneSeen,
            code: (possibleValue as DefinedTextValue)?.code,
          };
        }
      } else {
        return resultValue;
      }
    });
  }

  private modifyPresentationValueString(
    value: string,
    resultDefinition: ResultDefinition,
    interval: ResultInterval
  ): string {
    if (interval) {
      // Semi quantitative result
      return interval.customerFacingText;
    } else if (resultDefinition?.types) {
      // Defined text result
      const possibleValue = resultDefinition.types.find((type) => {
        return isDefinedTextValue(type) && value === type.code;
      });
      if (!possibleValue) {
        return value;
      }

      return (possibleValue as DefinedTextValue).display;
    } else {
      return value;
    }
  }

  setShowClinicalReference() {
    const isEmptyResult = this.assay?.result?.value?.emptyResult;

    this.showClinicalReference =
      (this.assay.clinicalReference &&
        this.assay?.status !== AssayStatus.OPENED &&
        this.assay?.status !== AssayStatus.REPEAT_REQUESTED &&
        (this.assay?.status !== AssayStatus.CANCELED ||
          (this.assay?.status === AssayStatus.CANCELED && this.assay.updatedResult?.value !== null)) &&
        !this.assay?.updatedResult?.noResult &&
        !this.assay?.repeatRequested) ||
      isEmptyResult ||
      (this.resultInput?.control?.dirty &&
        !this.assay?.updatedResult?.noResult &&
        this.resultInput?.control?.dirty &&
        !this.assay?.repeatRequested &&
        this.assay.resultDefinition?.valueType !== 'FREE_TEXT');
  }

  // Set card presentation based on result and status values,
  // and update presentation on user actions such as changing a result
  // and/or saying an assay has "No Result"
  setCardPresentation() {
    setTimeout(() => {
      if (this.assay) {
        this.getCardWrapperPresentation();
        this.setShowClinicalReference();
      }
    }, 0);
  }

  markAsTouchedDirty() {
    setTimeout(() => {
      this.resultInput?.control.markAsTouched();
      this.resultInput?.control.markAsDirty();
      this.setCardPresentation();
    }, 0);
  }

  markAsPristineUntouched() {
    setTimeout(() => {
      this.resultInput.control.markAsPristine();
      this.resultInput.control.markAsUntouched();
      this.setCardPresentation();
    }, 0);
  }

  handleClick($event) {
    $event.preventDefault();
    this.selectResultInput();
  }

  handleDoubleClick($event) {
    if (this.assay.updatedResult?.value && this.assay.canModify) {
      this.editMode = !this.editMode;
    }
  }

  selectResultInput() {
    setTimeout(() => {
      if (this.resultInput) {
        this.resultInput.focusInput();
      } else if (this.displayValueLink) {
        this.displayValueLink.nativeElement.focus();
      }
    }, 0);
  }

  handleFocusIn() {
    setTimeout(() => {
      if (!this.appStateService.currentAssay || this.cardWrapper.nativeElement.contains(document.activeElement)) {
        this.select();
      }
    }, 0);
  }

  handleFocusOut() {
    // if the user has changed the value of the updatedResult and we have a previousResult id -- null the previousResultId to forget the selection
    // this handles resetting our value when we tab/nav out of the assay, wherein no value change is going to be emitted.
    if (this.assay?.updatedResult?.previousResult) {
      this.assay.allResults.forEach((result) => {
        if (
          result.resultId === this.assay.updatedResult.previousResult &&
          this.assay.updatedResult.value !== result.value
        ) {
          this.assay.updatedResult.previousResult = null;
        }
      });
    }
  }

  // Catch emit from enter key
  handleResultSelection($event) {
    this.assay.updatedResult.previousResult = null;
    this.valueChange.emit($event);
  }

  handleMultiSelect($event) {
    if ($event && $event[0]?.isNoneSeen) {
      this.assay.updatedResult.previousResult = null;
      this.valueChange.emit($event);
    }
  }

  focusNext($event) {
    this.assay.updatedResult.previousResult = null;
    this.valueChange.emit($event);
  }

  select() {
    this.appStateService.currentAssay = this;
  }

  get selected(): boolean {
    return this.appStateService.currentAssay?.assay?.testCode === this.assay?.testCode;
  }

  bulkSelectAssay(toggle = false) {
    const checked = toggle
      ? !this.bulkSelectCheckbox.nativeElement.checked
      : this.bulkSelectCheckbox.nativeElement.checked;
    if (checked) {
      this.appStateService.bulkSelectAssay(this.assay);
      this.resultInput.focusInput();
    } else {
      this.appStateService.bulkUnselectAssay(this.assay);
      this.resultInput.focusInput();
    }
  }
}
