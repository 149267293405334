import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppStateService } from '../app-state.service';

/**
 * As of right now, this guy doesn't really do anything. Eventually we will have more workspace stuff, but not there
 * yet. If you are looking for where currentWorkspace is set, please see WorkspaceResolver and AccessionResolver.
 */
@Component({
  templateUrl: './workspace.component.html',
})
export class WorkspaceComponent implements OnDestroy {
  constructor(
    private title: Title,
    private appState: AppStateService
  ) {}

  ngOnDestroy() {
    this.appState.currentWorkspace = null;
    this.title.setTitle(this.appState.defaultPageTitle);
  }
}
