import { StandardWorkspaceAccession } from './workspace-accession.service';
import { Assay } from '../../interfaces/assay.interface';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Panel } from '../../panel/panel.interface';

export class LoadedAccessionInfo {
  accession: StandardWorkspaceAccession;
  resultAccession: Accession;
  assays: Assay[];
  panels: Panel[];
}
